import { truncate } from 'lodash';
import { string } from 'prop-types';
import React, { Component } from 'react';
import { InlineTextButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const BIO_COLLAPSED_LENGTH = 170;
const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};
class ExpandableBio extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, bio } = this.props;
    const truncatedBio = truncated(bio);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const handleShowLessClick = () => {
      this.setState({ expand: false });
    };
    const showMore = (
      <InlineTextButton
        rootClassName={css.showMore}
        onClick={handleShowMoreClick}
      >
        <FormattedMessage id="UserCard.showFullBioLink" />
      </InlineTextButton>
    );
    const showLess = (
      <InlineTextButton
        rootClassName={css.showMore}
        onClick={handleShowLessClick}
      >
        <FormattedMessage id="UserCard.showLessBioLink" />
      </InlineTextButton>
    );
    return (
      <span className={className}>
        {expand ? bio : truncatedBio}
        {bio !== truncatedBio && !expand
          ? showMore
          : expand == false
          ? null
          : showLess}
      </span>
    );
  }
}

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const SectionDescriptionMaybe = props => {
  const { description, showReviews } = props;
  return description ? (
    <div
      className={classNames(css.sectionDescription, {
        [css.sectionDescriptionWithoutReviews]: !showReviews,
      })}
    >
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <p className={css.description}>
        {description ? (
          <ExpandableBio className={css.mobileBio} bio={description} />
        ) : null}
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
