import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCollection, InlineTextButton } from '../../components';
import css from './ListingPage.module.css';
import { category } from '../../marketplace-custom-config';

const categoryLabel = (category, key) => {
  const cat = category.find(c => c.key === key);
  return cat ? cat.value : key;
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const SectionHeading = props => {
  const {
    richTitle,
    listingCertificate,
    certificateOptions,
    showContactUser,
    onContactUser,
    selectedCategory,
    isMobile,
    isOwnListing,
  } = props;
  const certificate = getCertificateInfo(
    certificateOptions,
    listingCertificate
  );
  const showCertificate = certificate && !certificate.hideFromListingInfo;
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <p className={css.subCategoryText}>{isOwnListing ? categoryLabel(category,selectedCategory) :null}</p>
       {/* <div className={css.author}>
          {showCertificate ? <span>{certificate.label}</span> : null}
          {showContactUser ? (
            <span className={css.contactWrapper}>
              {showCertificate ? (
                <span className={css.separator}>•</span>
              ) : null}
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div> */}
      </div>
      {/* <div><IconCollection name="LOCATION_POINT"/></div> */}
     </div>
  );
};

export default SectionHeading;
